import React, {useState} from 'react'

export default function TextForm(props) {

    const [text, setText] = useState("");
    const handleOnChange = (event) => {
        setText(event.target.value)
    }
    const handleUppercase = () => {
        setText(text.toUpperCase())
        props.showAlert("Converted to uppercase", "success")
    }
    const handleLowercase = () => {
        setText(text.toLowerCase())
        props.showAlert("Converted to lowercase", "success")

    }
    const handleCleartext = () => {
        setText("")
        props.showAlert("Text cleared", "success")

    }
    const handleCopyText = () => {
        const text = document.getElementById("textForm")
        text.select()
        navigator.clipboard.writeText(text.value)
        document.getSelection().removeAllRanges()
        props.showAlert("Text copied", "success")

    }
    const handleRmSpace = () => {
        const newText = text.split(/[ ]+/)
        setText(newText.join(" "))
        props.showAlert("Extra spaces removed", "success")

    }

    return (
        <div>
            <h1 className="mb-3 mx-3" style={{textAlign:"center"}}>{props.heading}</h1>
            <textarea className="container form-control" id="textForm" onChange={handleOnChange} value={text} rows="13"
                      style={{
                          background: props.mode === "dark" ? "#191919" : "#FFF",
                          color: props.mode === "dark" ? "#FFF" : "#191919"
                      }}
            ></textarea>
            <div className="container">
                <button type="button" disabled={text.length === 0} onClick={handleUppercase}
                        className="btn btn-outline-primary my-3 mx-5">Convert to Uppercase
                </button>
                <button type="button" disabled={text.length === 0} onClick={handleLowercase}
                        className="btn btn-outline-primary my-3 mx-5">Convert to Lower
                </button>
                <button type="button" disabled={text.length === 0} onClick={handleCleartext}
                        className="btn btn-outline-primary my-3 mx-5">Clear text
                </button>
                <button type="button" disabled={text.length === 0} onClick={handleCopyText}
                        className="btn btn-outline-primary my-3 mx-5">Copy text
                </button>
                <button type="button" disabled={text.length === 0} onClick={handleRmSpace}
                        className="btn btn-outline-primary my-3 mx-5">Remove extra space
                </button>
            </div>
            <div className="container">
                <h2>Summary</h2>
                <p>{(text.split(/\s+/).filter((element) => {
                    return element.length !== 0
                }).length)} words and {text.length} characters</p>
                <p> {text.split(" ").filter((element) => {
                    return element.length !== 0
                }).length * 0.008.toFixed(3)} Minute(s) of reading</p>
                <h3>Preview</h3>
                <p>{text.length > 0 ? `${text}` : "Nothing to preview"}</p>
            </div>
        </div>
    )
}

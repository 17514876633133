import React from 'react'
import PropTypes from "prop-types";

export default function Navbar(props) {
    return (
        <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/">{props.title}</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-linkuto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="/">{props.tag1}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://techbeas.co.in" target={"_blank"}
                               rel="noreferrer">{props.tag2}</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="about" id="navbarDropdown" role="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                {props.tag3}
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="https://moviesflix.techbeas.co.in"
                                       target={"_blank"} rel="noreferrer">Movies Flix</a></li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li><a className="dropdown-item" href="/">TextUtils</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" onClick={props.toggleMode} type="checkbox" id="flexSwitchCheckDefault" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Enable {props.mode==="dark"?"light":"dark"} mode</label>
                    </div>
            </div>
        </nav>
)
}


Navbar.defaultProps={
    title: "TextUtils",
        tag1: "Home",
        tag2: "About",
        tag3: "More Projects",
}

Navbar.propTypes = {
    title: PropTypes.string.isRequired,
        tag1: PropTypes.string.isRequired,
        tag2: PropTypes.string.isRequired,
        tag3: PropTypes.string.isRequired,
}
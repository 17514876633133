import './App.css';
import Navbar from "./components/Navbar";
import TextForm from "./components/TextForm";
import React, {useState} from "react";
import Alert from "./components/Alert";
import {Analytics} from "@vercel/analytics/react";

function App() {

    const [mode, setMode] = useState("dark");
    const [alert, setAlert] = useState(null);

    const showAlert = (message, type) =>{
        setAlert({
            msg: message,
            type:type
        })
        setTimeout(()=>{
            setAlert("")
        },1500)
    }


    const toggleMode = () => {
        if (mode === "dark") {
            setMode("light")
            showAlert("Light mode enabled","success")
            document.body.style.color = "#191919"
            document.body.style.backgroundColor = "#FFF"
        } else {
            setMode("dark")
            showAlert("Dark mode enabled","success")

            document.body.style.color = "#FFF"
            document.body.style.backgroundColor = "#191919"
        }
    }
    return (
        <div>
            <Navbar mode={mode} toggleMode={toggleMode}/>
            <Alert alert={alert}/>
            <TextForm heading="Try  TextUtils - Word counter, Character counter, Lowercase to uppercase, Uppercase to lowercase, Remove extra spaces" mode={mode} showAlert={showAlert}/>
            <Analytics />
        </div>
    );
}

export default App;
